import { AbilityBuilder, createMongoAbility } from '@casl/ability'

export const MODULES_ACTION = {
  CREATE: 'create',
  DELETE: 'delete',
  DETAIL: 'detail',
  MANAGE: 'manage',
  READ: 'read',
  UPDATE: 'update'
}

export const MODULES_NAME = {
  COMPANIES: 'Companies',
  COMPANY_PLAN: 'CompanyPlan',
  COMPANY_SIGNATURE: 'CompanySignature',
  COMPANY_STUDENTS: 'CompanyStudents',
  COMPANIES_SUMMARY: 'CompanySummary',
  COMPANY_PERSON: 'CompanyPerson',
  COMPANY_VENDOR: 'CompanyVendor',
  DASHBOARD_CRM: 'DashboardCrm',
  DASHBOARD_SALES: 'DashboardSales',
  DASHBOARD_SUBSCRIPTIONS: 'DashboardSubscriptions',
  ORDERS: 'Orders',
  PRODUCTS: 'Products',
  SUBSCRIPTION_FEATURES: 'SubscriptionFeatures',
  SUBSCRIPTION_FEATURES_ADDON: 'SubscriptionFeaturesAddOn',
  SUBSCRIPTION_FEATURES_ADDON_PRICE: 'SubscriptionFeaturesprice',
  SUBSCRIPTION_STATUS: 'SubscriptionStatus',
  SUBSCRIPTION_PACKAGES: 'SubscriptionPackages',
  SUBSCRIPTION_PACKAGES_DUES: 'SubscriptionPackagesDues',
  SUBSCRIPTION_LOG_ROOT: 'SubscriptionLogRoot',
  SUBSCRIPTION_LOG: 'SubscriptionLog',
  SUBSCRIPTION: 'Subscription',
  USERS_TENANT: 'UsersTenant',
  USERS_TENANT_VIEW: 'UsersTenantView',
  USERS_TENANT_ROLE: 'UsersTenantRole',
  USERS_TENANT_PROFILE: 'UsersTenantProfile',
  USERS_HUB: 'UsersHub'
}

export function defineAbilitiesForRoot () {
  const { can, rules } = new AbilityBuilder(createMongoAbility)

  // let ability;

  can(MODULES_ACTION.CREATE, MODULES_NAME.ORDERS)
  can(MODULES_ACTION.CREATE, MODULES_NAME.PRODUCTS)
  can(MODULES_ACTION.DETAIL, MODULES_NAME.COMPANIES)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANIES_SUMMARY)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANIES)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_PLAN)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_SIGNATURE)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_STUDENTS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_PERSON)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_VENDOR)
  can(MODULES_ACTION.READ, MODULES_NAME.DASHBOARD_SALES)
  can(MODULES_ACTION.READ, MODULES_NAME.DASHBOARD_SUBSCRIPTIONS)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_PACKAGES)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDERS)
  can(MODULES_ACTION.READ, MODULES_NAME.PRODUCTS)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_FEATURES)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_PACKAGES)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_LOG_ROOT)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_LOG)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_FEATURES_ADDON_PRICE)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_PLAN)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_SIGNATURE)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_STUDENTS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_PACKAGES)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_PACKAGES_DUES)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.ORDERS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.PRODUCTS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_FEATURES)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_STATUS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_STATUS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION)
  can(MODULES_ACTION.READ, MODULES_NAME.USERS_HUB)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_HUB)
  can(MODULES_ACTION.CREATE, MODULES_NAME.USERS_HUB)
  can(MODULES_ACTION.READ, MODULES_NAME.USERS_TENANT)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_TENANT)
  can(MODULES_ACTION.CREATE, MODULES_NAME.USERS_TENANT)
  can(MODULES_ACTION.READ, MODULES_NAME.USERS_TENANT_VIEW)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_TENANT_ROLE)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_TENANT_PROFILE)

  // ability = build();
  // return build()
  return rules
}

export function defineAbilitiesForAdmin () {
  const { can, rules } = new AbilityBuilder(createMongoAbility)

  // let ability;

  can(MODULES_ACTION.CREATE, MODULES_NAME.ORDERS)
  can(MODULES_ACTION.CREATE, MODULES_NAME.PRODUCTS)
  can(MODULES_ACTION.DETAIL, MODULES_NAME.COMPANIES)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANIES_SUMMARY)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANIES)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_PLAN)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_SIGNATURE)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_STUDENTS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_PERSON)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_VENDOR)
  can(MODULES_ACTION.READ, MODULES_NAME.DASHBOARD_SALES)
  can(MODULES_ACTION.READ, MODULES_NAME.DASHBOARD_SUBSCRIPTIONS)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_PACKAGES)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_LOG_ROOT)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_LOG)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDERS)
  can(MODULES_ACTION.READ, MODULES_NAME.PRODUCTS)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_FEATURES)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_FEATURES_ADDON_PRICE)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_PLAN)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_SIGNATURE)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_STUDENTS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_PACKAGES)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_PACKAGES_DUES)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.ORDERS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.PRODUCTS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_FEATURES)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_STATUS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_STATUS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION)
  can(MODULES_ACTION.READ, MODULES_NAME.USERS_HUB)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_HUB)
  can(MODULES_ACTION.CREATE, MODULES_NAME.USERS_HUB)
  can(MODULES_ACTION.READ, MODULES_NAME.USERS_TENANT)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_TENANT)
  can(MODULES_ACTION.CREATE, MODULES_NAME.USERS_TENANT)
  can(MODULES_ACTION.READ, MODULES_NAME.USERS_TENANT_VIEW)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_TENANT_ROLE)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_TENANT_PROFILE)
  // ability = build();

  // return build()
  return rules
}

export function defineAbilitiesForSupport () {
  const { can, rules } = new AbilityBuilder(createMongoAbility)

  can(MODULES_ACTION.READ, MODULES_NAME.ORDERS)
  can(MODULES_ACTION.DETAIL, MODULES_NAME.COMPANIES)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANIES_SUMMARY)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANIES)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_PLAN)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_SIGNATURE)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_SIGNATURE)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_STUDENTS)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_STUDENTS)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_FEATURES)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_PACKAGES)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_SIGNATURE)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_STUDENTS)
  can(MODULES_ACTION.READ, MODULES_NAME.USERS_TENANT)
  can(MODULES_ACTION.READ, MODULES_NAME.USERS_TENANT_VIEW)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_TENANT_ROLE)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_TENANT_PROFILE)

  // let ability = build()
  // return build()
  return rules
}

export function defineAbilitiesForSales () {
  const { can, rules } = new AbilityBuilder(createMongoAbility)

  can(MODULES_ACTION.CREATE, MODULES_NAME.ORDERS)
  can(MODULES_ACTION.DETAIL, MODULES_NAME.COMPANIES)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANIES)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_PLAN)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_SIGNATURE)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_STUDENTS)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDERS)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_FEATURES)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.ORDERS)
  can(MODULES_ACTION.READ, MODULES_NAME.USERS_TENANT)
  can(MODULES_ACTION.READ, MODULES_NAME.USERS_TENANT_VIEW)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_TENANT_ROLE)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_TENANT_PROFILE)

  // let ability = build()
  // return build()
  return rules
}

export function defineAbilitiesForDirectorSales () {
  const { can, rules } = new AbilityBuilder(createMongoAbility)

  can(MODULES_ACTION.CREATE, MODULES_NAME.ORDERS)
  can(MODULES_ACTION.DETAIL, MODULES_NAME.COMPANIES)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANIES_SUMMARY)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANIES)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_PLAN)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_SIGNATURE)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_STUDENTS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_PERSON)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_VENDOR)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDERS)
  can(MODULES_ACTION.READ, MODULES_NAME.PRODUCTS)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_FEATURES)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_PACKAGES)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_PACKAGES)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_LOG)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_PLAN)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.ORDERS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_FEATURES)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_PACKAGES)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_PACKAGES)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_STATUS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION)
  can(MODULES_ACTION.READ, MODULES_NAME.USERS_TENANT)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_TENANT)
  can(MODULES_ACTION.READ, MODULES_NAME.USERS_TENANT_VIEW)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_TENANT_ROLE)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_TENANT_PROFILE)

  // let ability = build()
  // return build()
  return rules
}

export function defineAbilitiesForLeaderSupport () {
  const { can, rules } = new AbilityBuilder(createMongoAbility)

  // can(MODULES_ACTION.READ, MODULES_NAME.ORDERS)
  can(MODULES_ACTION.DETAIL, MODULES_NAME.COMPANIES)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANIES_SUMMARY)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANIES)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_PLAN)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_SIGNATURE)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_SIGNATURE)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_STUDENTS)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_STUDENTS)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_FEATURES)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_PACKAGES)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_SIGNATURE)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_STUDENTS)
  can(MODULES_ACTION.READ, MODULES_NAME.USERS_TENANT)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_TENANT)
  can(MODULES_ACTION.CREATE, MODULES_NAME.USERS_TENANT)
  can(MODULES_ACTION.READ, MODULES_NAME.USERS_TENANT_VIEW)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_TENANT_ROLE)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_TENANT_PROFILE)

  // let ability = build()
  // return build()
  return rules
}
