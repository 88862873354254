import { extend, localize, setInteractionMode } from 'vee-validate'
import { email, max, mimes, regex, required, size } from 'vee-validate/dist/rules'

import { i18n } from '@/plugins/i18n/index'
import es from 'vee-validate/dist/locale/es.json'

setInteractionMode('lazy')
localize(es)

extend('required', {
  ...required,
  message: i18n.tc('FieldRequired')
})

extend('max', {
  ...max,
  message: i18n.tc('FieldMax')
})
setInteractionMode('lazy')

extend('email', {
  ...email,
  message: i18n.tc('FieldEmail')
})
extend('mimes', {
  ...mimes,
  message: i18n.tc('FieldMimes')
})
extend('size', {
  ...size,
  message: i18n.tc('FieldSize')
})
extend('regex', {
  ...regex,
  message: i18n.tc('FieldRegex')
})
