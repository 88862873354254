<template>
  <v-app class="">
    <v-main class="h-full grey lighten-5">
      <slot />
    </v-main>
  </v-app>
</template>

<script>
export default {}
</script>

<style>

</style>
