
const calculateValueSubscription = (
  frequencyBilling,
  plan,
  subscription,
  subscriptionFeatures,
  orderDetailAddOns,
  calculateType
) => {
  const subscriptionResult = {
    ...subscription,
    subscriptionPlanDiscount: 0,
    subscriptionPlanSubTotal: 0,
    subscriptionPlanTaxes: [],
    subscriptionPlanVatBase: 0,
    subscriptionPlanVatValue: 0,
    subscriptionPlanVatPercent: 0,
    subscriptionPlanAmountTaxes: 0,
    subscriptionPlanAmountTotal: 0,
    subscriptionFeaturesSubTotal: 0,
    subscriptionFeaturesTaxes: [],
    subscriptionFeaturesVatBase: 0,
    subscriptionFeaturesVatValue: 0,
    subscriptionFeaturesVatPercent: 0,
    subscriptionFeaturesAmountTaxes: 0,
    subscriptionFeaturesAmountTotal: 0,
    orderDetailAddOnsSubTotal: 0,
    orderDetailAddOnsVatBase: 0,
    orderDetailAddOnsVatValue: 0,
    orderDetailAddOnsVatPercent: 0,
    orderDetailAddOnsAmountTaxes: 0,
    orderDetailAddOnsAmountTotal: 0,
    subscriptionSubTotal: 0,
    subscriptionVatBase: 0,
    subscriptionVatValue: 0,
    subscriptionVatPercent: 0,
    subscriptionAmountTaxes: 0,
    subscriptionAmountTotal: 0,
    orderDetailAddOns: []
  }
  switch (frequencyBilling) {
    case 'Anual':
      subscriptionResult.subscriptionRecurrence = 'Yearly'
      break
    case 'Mensual':
      subscriptionResult.subscriptionRecurrence = 'Monthly'
      break
    case 'Trimestral':
      subscriptionResult.subscriptionRecurrence = 'Quarterly'
      break
    case 'Semestral':
      subscriptionResult.subscriptionRecurrence = 'Semiannual'
      break
    default:
      subscriptionResult.subscriptionRecurrence = frequencyBilling
  }

  switch (subscriptionResult.subscriptionRecurrence) {
    case 'Yearly':
      subscriptionResult.planPrice = plan.planPriceYearly
      break
    case 'Monthly':
      subscriptionResult.planPrice = plan.planPriceMonthly
      break
    default:
      subscriptionResult.planPrice = 0
  }

  let discountValue = 0
  if (subscriptionResult.couponDiscountType === 'Flat' && +subscriptionResult.couponDiscount > 0) {
    discountValue = subscriptionResult.couponDiscount
  } else if (subscriptionResult.couponDiscountType === 'Percent' && +subscriptionResult.couponDiscount > 0) {
    discountValue = +(subscriptionResult.planPrice * (subscriptionResult.couponDiscount / 100)).toFixed(0)
  }
  if (calculateType === 'Periodicidad' && subscriptionResult.couponRedemptionType !== 'Forever') {
    subscriptionResult.subscriptionPlanSubTotal = subscriptionResult.planPrice
  } else {
    subscriptionResult.subscriptionPlanSubTotal = subscriptionResult.planPrice - discountValue
    subscriptionResult.subscriptionPlanDiscount = discountValue
  }

  if (plan.taxes != null) {
    subscriptionResult.subscriptionPlanTaxes = plan.taxes.map(item => ({
      taxName: item.taxName,
      taxIsSum: item.taxIsSum,
      taxRate: +item.taxRate,
      taxBase: +subscriptionResult.subscriptionPlanSubTotal,
      taxValue: +((+item.taxRate / 100) * subscriptionResult.subscriptionPlanSubTotal).toFixed(0)
    }))

    const subscriptionPlanTaxesVat = subscriptionResult.subscriptionPlanTaxes
      .filter(item => item.id === 1 && item.taxValue > 0)

    subscriptionResult.subscriptionPlanVatBase = subscriptionPlanTaxesVat
      .reduce((previousValue, currentValue) => previousValue + currentValue.taxBase, 0)

    subscriptionResult.subscriptionPlanVatPercent = subscriptionPlanTaxesVat
      .reduce((previousValue, currentValue) => previousValue + currentValue.taxPercent, 0) / (subscriptionPlanTaxesVat.length === 0 ? 1 : subscriptionPlanTaxesVat.length)

    subscriptionResult.subscriptionPlanVatValue = subscriptionPlanTaxesVat
      .reduce((previousValue, currentValue) => previousValue + currentValue.taxValue, 0)

    const subscriptionPlanTaxes = subscriptionResult.subscriptionPlanTaxes
      .filter(item => item.taxSum)

    if (subscriptionPlanTaxes && subscriptionPlanTaxes.length > 0) {
      subscriptionResult.subscriptionPlanAmountTaxes = subscriptionPlanTaxes
        .reduce((previousValue, currentValue) => previousValue + currentValue.taxValue, 0)
    }
  }

  subscriptionResult.subscriptionPlanAmountTotal = subscriptionResult.subscriptionPlanSubTotal +
    subscriptionResult.subscriptionPlanAmountTaxes

  if (subscriptionFeatures && subscriptionFeatures.length > 0) {
    subscriptionFeatures.forEach(subscriptionFeature => {
      if (subscriptionFeature.featureAccessAddon && subscriptionFeature.featurepriceAddon > 0) {
        subscriptionResult.subscriptionFeaturesSubTotal += subscriptionFeature.featurepriceAddon
      } else if (subscriptionFeature.featureQuantityAddon > 0) {
        subscriptionResult.subscriptionFeaturesSubTotal += Math.Round(subscriptionFeature.featureQuantityAddon * subscriptionFeature.featurepriceAddon, 0)
      }
    })

    subscriptionResult.subscriptionFeatures = subscriptionFeatures

    if (plan.taxes != null) {
      subscriptionResult.subscriptionFeaturesTaxes = plan.taxes.map(item => ({
        taxName: item.taxName,
        taxIsSum: item.taxIsSum,
        taxRate: +item.taxRate,
        taxBase: +subscriptionResult.subscriptionFeaturesSubTotal,
        taxValue: +((item.taxRate / 100) * subscriptionResult.subscriptionFeaturesSubTotal).toFixed(0)
      }))

      subscriptionResult.subscriptionFeaturesVatBase = subscriptionResult.subscriptionFeaturesTaxes
        .filter(item => item.id === 1 && item.taxValue > 0)
        .reduce((previousValue, currentValue) => previousValue + currentValue.taxBase, 0)

      subscriptionResult.subscriptionFeaturesVatValue = subscriptionResult.subscriptionFeaturesTaxes
        .filter(item => item.id === 1 && item.taxValue > 0)
        .reduce((previousValue, currentValue) => previousValue + currentValue.taxValue, 0)

      const subscriptionFeaturesTaxesVat = subscriptionResult.subscriptionFeaturesTaxes
        .filter(item => item.id === 1 && item.taxValue > 0)

      subscriptionResult.subscriptionFeaturesVatPercent = subscriptionFeaturesTaxesVat
        .reduce((previousValue, currentValue) => previousValue + currentValue.taxValue, 0) / (subscriptionFeaturesTaxesVat.length === 0 ? 1 : subscriptionFeaturesTaxesVat.length)

      subscriptionResult.subscriptionFeaturesAmountTaxes = subscriptionResult.subscriptionFeaturesTaxes
        .reduce((previousValue, currentValue) => previousValue + currentValue.taxValue, 0)
    }
  }

  subscriptionResult.subscriptionFeaturesAmountTotal = subscriptionResult.subscriptionFeaturesSubTotal +
    subscriptionResult.subscriptionFeaturesAmountTaxes

  if (orderDetailAddOns && orderDetailAddOns.length > 0) {
    const subscriptionAddOnFeatures = orderDetailAddOns.filter(item => item.featureId > 0 &&
      (
        (item.addOnPricingInterval === 'Recurring' && calculateType === 'Periodicidad') ||
        (item.addOnPricingInterval === 'OneTime' && calculateType === 'Apertura')
      ))

    if (subscriptionAddOnFeatures.length > 0) {
      for (let index = 0; index < subscriptionAddOnFeatures.length; index++) {
        const subscriptionAddOnFeature = subscriptionAddOnFeatures[index]
        if (subscriptionAddOnFeature.access && subscriptionAddOnFeature.price > 0) {
          subscriptionAddOnFeature.addOnSubTotal = +subscriptionAddOnFeature.price
        } else if (subscriptionAddOnFeature.addOnQuantity > 0) {
          subscriptionAddOnFeature.addOnSubTotal = +(subscriptionAddOnFeature.addOnQuantity * subscriptionAddOnFeature.price).toFixed(0)
        }

        if (plan.taxes != null) {
          subscriptionAddOnFeature.taxes = plan.taxes.map(item => ({
            taxName: item.taxName,
            taxIsSum: item.taxIsSum,
            taxRate: +item.taxRate,
            taxBase: +subscriptionAddOnFeature.addOnSubTotal,
            taxValue: +Math.Round((+item.taxRate / 100) * subscriptionAddOnFeature.addOnSubTotal, 0)
          }))

          subscriptionAddOnFeature.addOnVatBase = subscriptionAddOnFeature.taxes
            .filter(item => item.id === 1 && item.taxValue > 0)
            .reduce((previousValue, currentValue) => previousValue + currentValue.taxBase, 0)

          subscriptionAddOnFeature.addOnVatValue = subscriptionAddOnFeature.taxes
            .filter(item => item.id === 1 && item.taxValue > 0)
            .reduce((previousValue, currentValue) => previousValue + currentValue.taxValue, 0)

          const addOnsTaxes = subscriptionResult.taxes
            .filter(item => item.id === 1 && item.taxValue > 0)

          subscriptionAddOnFeature.addOnVatPercent = addOnsTaxes
            .reduce((previousValue, currentValue) => previousValue + currentValue.taxValue, 0) / (subscriptionAddOnFeature.taxes.length === 0 ? 1 : subscriptionAddOnFeature.taxes.length)
        }

        subscriptionResult.orderDetailAddOns.Add(subscriptionAddOnFeature)
      }
    }

    const subscriptionAddOnFiltered = orderDetailAddOns.filter(
      item => item.featureId === 0 &&
        (
          (item.addOnPricingInterval === 'OneTime' && calculateType === 'Apertura') ||
          calculateType !== 'Apertura'
        )
    )

    for (let index = 0; index < subscriptionAddOnFiltered.length; index++) {
      const subscriptionAddOn = subscriptionAddOnFiltered[index]

      subscriptionAddOn.addOnSubTotal = subscriptionAddOn.price

      if (subscriptionAddOn.taxes === null ||
        subscriptionAddOn.taxes.length === 0 ||
        subscriptionAddOn.pricingSchema !== 'Unit'
      ) {
        subscriptionResult.orderDetailAddOns.push(subscriptionAddOn)

        continue
      }

      subscriptionAddOn.taxes = subscriptionAddOn.taxes.map(item => ({
        id: item.id,
        taxCategory: item.taxCategory,
        taxName: item.taxName,
        taxRate: +item.taxRate,
        taxIsSum: item.taxIsSum,
        taxBase: +subscriptionAddOn.addOnSubTotal,
        taxValue: +((item.taxRate / 100) * subscriptionAddOn.addOnSubTotal).toFixed(0)
      }))

      const addOnsTaxesVat = subscriptionAddOn.taxes
        .filter(item => item.id === 1 && item.taxValue > 0)

      subscriptionAddOn.addOnVatBase = addOnsTaxesVat
        .reduce((previousValue, currentValue) => previousValue + currentValue.taxBase, 0)

      subscriptionResult.addOnVatPercent = addOnsTaxesVat
        .reduce((previousValue, currentValue) => previousValue + currentValue.taxValue, 0) / (addOnsTaxesVat.length === 0 ? 1 : addOnsTaxesVat.length)

      subscriptionAddOn.addOnVatValue = addOnsTaxesVat
        .reduce((previousValue, currentValue) => previousValue + currentValue.taxValue, 0)

      subscriptionResult.orderDetailAddOns.push(subscriptionAddOn)
    }

    if (subscriptionResult.orderDetailAddOns && subscriptionResult.orderDetailAddOns.length > 0) {
      subscriptionResult.orderDetailAddOnsSubTotal = subscriptionResult.orderDetailAddOns.reduce((previousValue, currentValue) => previousValue + currentValue.addOnSubTotal, 0)
      subscriptionResult.orderDetailAddOnsVatBase = subscriptionResult.orderDetailAddOns.reduce((previousValue, currentValue) => previousValue + currentValue.addOnVatBase, 0)
      subscriptionResult.orderDetailAddOnsVatPercent = subscriptionResult.orderDetailAddOns.reduce((previousValue, currentValue) => previousValue + currentValue.addOnVatPercent, 0)
      subscriptionResult.orderDetailAddOnsVatValue = subscriptionResult.orderDetailAddOns.reduce((previousValue, currentValue) => previousValue + currentValue.addOnVatValue, 0)

      subscriptionResult.orderDetailAddOnsAmountTaxes = subscriptionResult.orderDetailAddOns
        .reduce(
          (previousValue, currentValue) => {
            if (!currentValue.taxes || currentValue.taxes.length === 0) {
              return previousValue
            }

            const addOnsTaxes = currentValue.taxes.filter(item => item.taxIsSum)

            if (!addOnsTaxes || addOnsTaxes.length === 0) {
              return previousValue
            }

            return previousValue +
            addOnsTaxes.reduce(
              (previousValueInner, currentValueInner) => previousValueInner + currentValueInner.taxValue,
              0
            )
          },
          0
        )

      subscriptionResult.orderDetailAddOnsAmountTotal = subscriptionResult.orderDetailAddOnsSubTotal +
      subscriptionResult.orderDetailAddOnsAmountTaxes
    }
  }

  subscriptionResult.subscriptionSubTotal = subscriptionResult.subscriptionPlanSubTotal +
    subscriptionResult.subscriptionFeaturesSubTotal +
    subscriptionResult.orderDetailAddOnsSubTotal

  subscriptionResult.subscriptionVatBase = subscriptionResult.subscriptionPlanVatBase +
    subscriptionResult.subscriptionFeaturesVatBase +
    subscriptionResult.orderDetailAddOnsVatBase

  subscriptionResult.subscriptionVatValue = subscriptionResult.subscriptionPlanVatValue +
    subscriptionResult.subscriptionFeaturesVatValue +
    subscriptionResult.orderDetailAddOnsVatValue

  if (subscriptionResult.subscriptionPlanVatPercent > 0) {
    subscriptionResult.subscriptionVatPercent = subscriptionResult.subscriptionPlanVatPercent
  } else if (subscriptionResult.subscriptionFeaturesVatPercent > 0) {
    subscriptionResult.subscriptionVatPercent = subscriptionResult.subscriptionFeaturesVatPercent
  } else if (subscriptionResult.orderDetailAddOnsVatPercent > 0) {
    subscriptionResult.subscriptionVatPercent = subscriptionResult.orderDetailAddOnsVatPercent
  }

  subscriptionResult.subscriptionAmountTaxes = subscriptionResult.subscriptionPlanAmountTaxes +
  subscriptionResult.subscriptionFeaturesAmountTaxes +
  subscriptionResult.orderDetailAddOnsAmountTaxes

  subscriptionResult.subscriptionAmountTotal = subscriptionResult.subscriptionPlanAmountTotal +
    subscriptionResult.subscriptionFeaturesAmountTotal +
    subscriptionResult.orderDetailAddOnsAmountTotal

  return subscriptionResult
}

export const calculateBilledValue = (feature) => {
  let billedValue = 0
  if (feature.kind === 'Access' && feature.accessAddon === true) {
    billedValue = +(+feature.priceAddon).toFixed(2)
  } else {
    billedValue = +(+feature.quantityAddon * +feature.priceAddon).toFixed(2)
  }

  return billedValue > 0 ? billedValue : 0
}

export const calculateRecurrenceNumber = (recurrence) => {
  let number = 0
  switch (recurrence) {
    case 'Yearly': number = 12; break
    case 'Monthly': number = 1; break
    case 'Quarterly': number = 3; break
    case 'Semiannual': number = 6; break
    case 'ThreeYearly': number = 36; break
    case 'EighthYearly': number = 96; break
    default: number = 0
  }

  return number
}

export default calculateValueSubscription
